import React, { useEffect, useState } from 'react'
import '../../Login.css'
import logo from '../../images/logo.png'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
export default function Login() {

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const [logindata, setlogindata] = useState({
        username:'',
        password:''
    })
    const navigate=useNavigate()
    const [msg, setmsg] = useState()
    const [dashbaord, setdashboard] = useState(0)
    const API_PATH = `${SERVER_URL}login.php`;
    function Submitlogin(){
        axios({
            method:'post',
            url:`${API_PATH}`,
            data:logindata
        }).then((res)=>{
          if(res.data.status==1){
            sessionStorage.setItem('userid',res.data.userid)
            sessionStorage.setItem('role',res.data.role)
            sessionStorage.setItem('branch',res.data.branch)
            setmsg(res.data.message)
            setdashboard(1)
          }else{
            setmsg(res.data.message)
          }
        
   
      
      
        }).catch((err)=>console.log(err))
    }

    useEffect(() => {
      if(dashbaord==1){
        window.location.replace('/dashboard')
        // navigate('dashboard')
      }
      
    }, [dashbaord])
    
  return (
    <div className="wrapper fadeInDown">
        <div id="formContent">
          {/* Tabs Titles */}
          {/* Icon */}
          <div className="fadeIn first" style={{marginTop: '10%'}}>
            <img src={logo} id="icon" alt="User Icon" style={{width: '40%'}} />
            {/* <h3 className="loginhead">Fleetp</h3> */}
          </div>
          <p id="sign"> Sign In </p>
          {/* Login Form */}
          <form>
            <input type="text" id="username" className="fadeIn second" name="username" onChange={(e)=>setlogindata({...logindata,username:e.target.value})} placeholder="username" style={{textAlign: 'left',width: '-webkit-fill-available'}} />
            <input type="text" id="password" className="fadeIn second" name="password"  onChange={(e)=>setlogindata({...logindata,password:e.target.value})} placeholder="password" style={{textAlign: 'left',width: '-webkit-fill-available'}} />
            <p id="msg" />  
            <a onClick={Submitlogin} className="login" style={{cursor: 'pointer',color:'#fff'}}>Log In</a>
      <p>{msg}</p>
          </form>
          {/* Remind Passowrd */}
          <div id="formFooter">
          </div>
        </div>
      </div>
  )
}
